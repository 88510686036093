.popup-shop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(18, 1, 3, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center; /* горизонтали */
  align-items: center; /*  по вертикали */
  justify-content: flex-start;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 4;
  /* width: minmax(360px, 440px); */
  gap: 5px;
  padding: 40px 20px 0;
}

.popup-shop_visible {
  opacity: 1;
}

.popup-shop__content {
  padding: 20px 20px;
  border-radius: 8px;
  width: 100%;
  position: relative;
  background: rgba(18, 1, 3, 1);
}

.popup-shop__header {
  position: absolute;
  top: 20px;
  left: 20px;
  margin: 0;
  font-size: 18px;
  color: #fff;
}

.popup-shop__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
  height: 16px;
  width: 16px;
  background-image: url(../../images/exit.svg);
}

.popup-shop__title-box {
  display: flex;
  gap: 10px;
}

.popup-shop__title {
  font-size: 18px;
  color: #fff;
  text-align: start;
  margin-top: 13px;
}

.popup-shop__title-red {
  color: rgba(219, 48, 17, 1);
  font-weight: 600;
}

.popup-shop__list {
  padding: 0;
  gap: 20px;
  margin: 0;
  padding-bottom: 10px;
}

.popup-shop__list-item {
  position: relative;
  font-size: 16px;
  color: #fff;
  list-style: none;
  padding-left: 36px;
  text-align: left;
  margin-bottom: 20px;
}

.popup-shop__list-item::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(../../images/li.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.popup-shop__button {
  font-size: 18px;
  color: #fff;
  border: none;
  background: rgba(117, 28, 37, 1);
  height: 50px;
  width: 100%;
}

.popup-shop__star-image {
  margin: 0;
  width: 22px;
  height: 21px;
  margin: 17px 0;
}

@media (max-width: 380px) {
  .popup-shop__list-item {
    font-size: 14px;
  }
  .popup-shop__title {
    font-size: 16px;
  }
}
