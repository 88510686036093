.unauthorized {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.unauthorized__box {
  padding-top: 55px;
}


.unauthorized__title{
  font-family: "Gunter";
  color: rgba(219, 48, 17, 1);
  font-size: 90px;
  margin: 0;
}

.unauthorized__title:last-of-type {
  padding-top: 5px;
}

.unauthorized__title-small {
  font-size: 40px;
}

.unauthorized__image {
  background-image: url(../../images/Photoroom.png);
  width: 100vw;
  height: 65vh;
  min-height: fit-content;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 430px) {
  .unauthorized__title{
      font-size: 80px;
  }
  .unauthorized__title-small {
    font-size: 35px;
  }
  .unauthorized__image {
    width: 100vw;
    height: 65vh;
  }
}
