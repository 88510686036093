@font-face {
  font-family: 'SF Pro Display';
  src: url('./SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gunter';
  src: url('./guntertest-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ristretto Slab Pro";
  src: url("./RistrettoSlabPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

