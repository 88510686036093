.form {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 1, 3, 0.98);
  z-index: 4;
  padding: 100px 20px 0;
  gap: 10px;
  overflow: hidden;
}

.form__title-box {
  gap: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 90px;
}

.form__title {
  font-size: 18px;
  text-align: left;
  margin: 0;
}

.form__title_larger {
  font-size: 38px;
  color: rgba(219, 48, 17, 1);
  font-weight: 700;
}

.popup-shop__text {
  font-size: 16px;
  padding-left: 36px;
  text-align: left;
}

.form__error {
  color: rgba(219, 48, 17, 1);
}

.form__input {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  min-height: 50px;
  padding-left: 10px;
}

.popup__text {
  text-align: left;
  margin: 0;
}

.popup__text_larger {
  text-align: center;
  font-size: 22px;
  white-space: pre-wrap;
  margin-top: 80px;
}

.form__checkbox-box {
  display: flex;
  gap: 12px;
  margin-top: 10px;
}

.form__checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.form__checkbox_custom {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
}

.form__checkmark {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  background-color: transparent;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.form__checkbox:checked + .form__checkmark::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form__button {
  font-size: 18px;
  color: #fff;
  border: none;
  background: rgba(117, 28, 37, 1);
  height: 50px;
  width: 91%;
  padding: 0;
  position: fixed;
  bottom: 20px;
  transition: opacity 0.3s ease;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 380px) {
  .popup-shop__text {
    font-size: 14px;
  }
}
