.popup-alert {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  height: 60px;
  background: rgba(43, 4, 8, 0.9);
  display: flex;
  justify-content: center; /* горизонтали */
  align-items: center; /*  по вертикали */
  z-index: 5;
  transition: top 400ms ease-in-out;
}

.popup-alert_visible {
  top: 0;
}

.popup-alert__message {
  font-size: 18px;
  color: rgba(219, 48, 17, 1);
}
