body {
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  text-align: center;
}

html, body {
  overflow: hidden;
}

.App {
  min-width: 320px;
  max-width: 1280px;
  background-position: center;
  background-size: cover;
  font-family: "SF Pro Display", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
}

.App-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(./images/bacground.jpg) no-repeat center center / cover;
  z-index: -2;
  filter: sepia(2%);
  overflow: hidden;
}
.fog {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(./images/fog-2.png) repeat;
  opacity: 1;
  animation: fogAnimation 15s ease-in-out infinite;
}


@keyframes fogAnimation {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}



.App-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
}

.description {
  margin: 0 auto;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  line-height: 21.48px;
  font-weight: 400;
  text-align: left;
  width: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 2s ease;
  position: fixed;
  top: 345px;
  bottom: 0;
  padding-bottom: 100px;
  mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
  overscroll-behavior: contain;
}

.description-text{
  margin: 0;
}

.description-visible {
  opacity: 1;
}

@media (max-width: 425px) {
  .description {
    /* font-size: 16px; */
    top: 315px;
    padding-bottom: 80px;
  }
}
