.card {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(43, 4, 8, 1);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  align-items: center;
  width: 123px;
  height: 214px;
  gap: 2px;
}

.card__image {
  width: 111px;
  height: 178px;
  border-radius: 2px;
  margin-top: 6px;
}

.card__name {
  font-family: "Ristretto Slab Pro";
  vertical-align: middle;
  font-size: 16px;
  margin: 4px auto 1px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 1px 1px 1px rgba(27, 0, 17, 0.3);
}

@media (max-width: 425px) {
  .card {
    width: 110px;
    height: 182px;
  }
  .card__image {
    width: 94px;
    height: 151px;
    margin-top: 8px;
  }
  .card__name {
    margin-top: 0;
  }
}

@media (max-width: 385px) {
  .card {
    width: 105px;
    height: 173px;
  }
  .card__image {
    width: 90px;
    height: 144px;
    margin-top: 8px;
  }
}

@media (max-width: 370px) {
  .card {
    width: 100px;
    height: 165px;
  }
  .card__image {
    width: 86px;
    height: 137px;
  }
}
