.cards__grid {
  display: flex;
  flex-flow: wrap;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: space-between;
  width: 100%;
  height: 60vh;
  flex-direction: row;
  margin: 0 auto;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
