.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(18, 1, 3, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 90%;
}

.popup-image {
  max-width: 90%;
  height: auto;
}

.close-button {
  font-size: 18px;
  background-color: rgba(141, 26, 5, 1);
  color: #fff;
  width: 100%;
  border: none;
  padding: 10px;
  height: 50px;
  cursor: pointer;
}

.popup__description {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
}

.popup__title {
  font-family: "Ristretto Slab Pro";
  font-weight: 200;
  vertical-align: middle;
  font-size: 26px;
  margin: 4px auto 1px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(27, 0, 17, 0.3);
}
