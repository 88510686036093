.menu {
  position: fixed;
  background: none;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  display: none;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
}


.menu__link {
  color: aliceblue;
  opacity: 0.2;
  text-decoration: none;
  align-content: center;
  margin: 0 15px;
}
