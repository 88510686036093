.deck__title {
  margin: 70px 0 0 0;
  text-align: center;
  color: rgba(219, 48, 17, 1);
  font-weight: 400;
  font-family: "Gunter";
  font-size: 32px;
  line-height: 33.6px;
  white-space: nowrap;
  transition: margin 2s ease;
}

.deck__title-smaller {
  font-size: 28px;
  margin-top: 30px;
  text-align: left;
}

.top-card {
  position: relative;
}

.deck__card-back-z {
  visibility: hidden;
  background: rgba(43, 4, 8, 1);
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 301px;
  height: 518px;
  border-radius: 5px;
  top: -38px;
  left: 0;
  justify-content: center;
  align-items: center;
  gap: 20px;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 600ms ease-out, transform 800ms ease;
  box-shadow: 5px 5px 5px 10px rgba(0, 0, 0, 0.8);
}

.deck__card-back-z_shown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.deck__card-image {
  width: 90%;
}

.deck__card-title {
  font-family: "Ristretto Slab Pro";
  font-size: 28px;
  margin: 0;
}

@keyframes heartbeat {
  0%, 20%, 100% {
      transform: scale(1);
  }
  10% {
      transform: scale(1.01);
  }
  25% {
      transform: scale(1.01);
  }
  30% {
      transform: scale(1);
  }
}

.deck__card-back {
  background: url(../../images/back.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 301px;
  height: 518px;
  opacity: 0;
  transition: opacity 2s ease, transform 100ms ease;
  box-shadow: 5px 10px 10px 10px rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  animation: heartbeat 2400ms infinite;

}

/* .deck__card-back_animated {
  animation: heartbeat 2500ms infinite;
} */

.deck__card-back_clicked {
  transform: scale(0.95);
}

.deck__card-back-visible {
  opacity: 1;
}

.question__box {
  height: fit-content;
  display: flex;
  opacity: 0;
  transition: opacity 2s ease;
}

.question__box-visible {
  opacity: 1;
}

.deck__button-input {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(219, 48, 17, 1);
  border: none;
  background-size: cover;
  min-width: 50px;
  height: 50px;
  padding: 0;
  transition: background-color 0.2s ease;
}

.deck__text {
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  line-height: 21.48px;
  font-weight: 400;
  text-align: left;
  opacity: 0;
  transition: opacity 1s ease;
}

.deck__text-visible {
  opacity: 1;
}

.deck__input {
  color: rgba(219, 48, 17, 1);
  background: rgba(43, 4, 8, 1);
  box-shadow: 5px 5px 5px rgba(80, 80, 80, 0.1);
  border: solid;
  padding: 0 20px;
  border: none;
  width: 90%;
  height: 50px;
  transition: background-color 0.2s ease;
  font-size: 18px;
}

.deck__input::placeholder {
  color: rgba(184, 35, 51, 1);

  font-size: 18px;
}

.deck__input-clicked {
  background: rgba(141, 26, 5, 1); /* Изменённый цвет при нажатии */
}

.deck__button-input.clicked {
  background: rgba(141, 26, 5, 1); /* Изменённый цвет нажатия */
}

.deck__buttons {
  display: flex;
  width: 90%;
  height: 50px;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s ease;
  position: fixed;
  bottom: 3vh;
}

.deck__buttons-visible {
  opacity: 1;
}

.deck__button {
  color: rgba(255, 255, 255, 1);
  background: rgba(219, 48, 17, 1);
  border: none;
  width: 50%;
  margin: 0;
  font-size: 18px;
  font-family: "SF Pro Display";
}

.deck__button-dark {
  background: rgba(141, 26, 5, 1);
}

.deck__input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.deck__button-input_image {
  position: relative;
  z-index: 2;
  background: url(../../images/submit.svg);
  width: 24px;
  height: 24px;
}

.tarot-button {
  width: 60px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid rgba(10, 10, 10, 0.1);
  background: rgba(80, 80, 80, 0.1);
  align-content: center;
  color: aliceblue;
  font-size: 12px;
  box-shadow: 5px 5px 5px rgba(80, 80, 80, 0.2);
}

.deck__button-save {
  background: rgba(80, 80, 80, 0.1);
  border: 1px solid rgba(10, 10, 10, 0.1);
  align-content: center;
  border-radius: 5px;
  color: aliceblue;
  font-size: 12px;
  padding: 5px 10px;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 5px 5px 5px rgba(80, 80, 80, 0.2);
}

.deck {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  padding: 0 20px;
  gap: 40px;
}

.deck-mingap {
  gap: 15px;
}

.cards {
  display: grid;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0;
  column-gap: 5px;
  align-items: center;
  height: fit-content;
}

.card-cell {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  border-radius: 1px;
  justify-content: center;
  justify-self: center;
  opacity: 0;
  transition: opacity 1s ease;
  border: 1px solid rgba(81, 20, 26, 1);
}

.card-cell-visible {
  opacity: 1;
}

.card-cell:first-of-type {
  justify-self: flex-start;
}

.card-cell:last-of-type {
  justify-self: flex-end;
}

.card-stack {
  margin: 20px 0 30px;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 504px;
  padding-top: 10px;
}

.new-card {
  position: relative;
  margin-top: 5px;
  margin-left: 3px;
}

.new-card.animate {
  transform: translateY(-100px);
  opacity: 0;
}

.deck__loader {
  width: 200px;
  height: 200px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="rgba(81, 20, 26, 1)" stroke="rgba(81, 20, 26, 1)" stroke-width="10" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="3.4" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="rgba(81, 20, 26, 1)" stroke="rgba(81, 20, 26, 1)" stroke-width="10" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="3.4" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="rgba(81, 20, 26, 1)" stroke="rgba(81, 20, 26, 1)" stroke-width="10" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="3.4" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  align-self: center;
}

.deck__loader-visible {
  opacity: 1;
}

@media (max-width: 425px) {
  .deck__title {
    font-size: 28px;
  }
  .deck__card-back {
    width: 250px;
    height: 430px;
    background-size: contain;
  }
  .deck__card-back-z {
    width: 255px;
    height: 430px;
    gap: 8px;
  }
  .deck__input {
    width: 88%;
    font-size: 16px;
  }
  .deck__input::placeholder {
    font-size: 16px;
  }
  .deck-controls {
    padding: 0;
    padding: 0;
    align-items: flex-start;
  }
  /* .deck__button {
    font-size: 16px;
  } */
}

@media (max-width: 370px) {
  .deck__title {
    font-size: 26px;
  }
  .deck__input {
    font-size: 14px;
  }
  .deck__input::placeholder {
    font-size: 14px;
  }
}
